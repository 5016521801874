@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_content {
    padding: 64px 0;
    overflow: hidden;

    .grid-x {
        z-index: 1;
        position: relative;
    }

    p {
        margin-top: 24px;
        margin-bottom: 0;
    }

    ul {
        margin-top: 16px;
    }

    .button {
        margin: 32px 8px 0 8px;
    }

    .date {
        margin-top: 24px;
        display: block;
    }

    .category-tags {
        position: relative;
        top: 16px;
        left: 0;
    }
}

.hero-content-image {
    margin-top: -64px;
    margin-bottom: 42px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

;@import "sass-embedded-legacy-load-done:105";