@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "_default/block_hero_content.scss";

.block_hero_content {
    background: linear-gradient(90deg, $color-primary 0%, $color-secondary 154.72%);

    &.has-image {
        padding: 64px 0 128px 0;
    }

    .radial {
        background: radial-gradient(48.95% 87.18% at 67.27% 43.43%, $color-radial-one 0%, rgba($color-primary, 0) 100%);
        position: absolute;
        width: 2094px;
        height: 1240px;
        right: -110%;
        bottom: 0;
        opacity: 0.6;
        z-index: 0;

        @include breakpoint(xxlarge) {
            right: -25%;
        }
    }

    @include breakpoint(large down) {
        .heading-h2 {
            margin-top: 48px;
        }
    }

    .heading-h2,
    ul {
        color: $color-white;
    }

    p {
        color: rgba($color-white, 0.9);
    }

    .date {
        color: $color-white;
        font-size: $font-s;
        font-weight: $semibold;
        line-height: 125%;
    }

    .top a {
        color: $color-white;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
